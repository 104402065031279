import React from 'react'
import Box from '../primitives/Box'
import Row from '../primitives/Row'
import GoodreadsLogo from '../../assets/images/social-icons/Goodreads.svg'
import Button from '../system/Button'

const RATING = '4.24'

export function GoodreadsRating() {
  const numericRating = parseFloat(RATING)
  return (
    <Row space={4}>
      <Stars rating={numericRating} />
      <Box>
        rated <b>{RATING}</b> stars on
      </Box>

      <Button
        variant="underlineLink"
        href="https://www.goodreads.com/book/show/200427131-build-elements-of-an-effective-software-organization"
        display="flex"
        alignItems="center"
        gap={4}
      >
        <img src={GoodreadsLogo} alt="Goodreads logo" />
        Goodreads
      </Button>
    </Row>
  )
}

function Stars(props: { rating: number }) {
  const { rating } = props
  const percentages = Array.from({ length: 5 }, (_, i) => {
    const value = rating - i
    return value >= 1 ? 1 : value > 0 ? value : 0
  })
  return (
    <Row alignItems="center">
      {percentages.map((p, i) => (
        <Star key={i} id={`build-goodreads-star-${i + 1}`} fillPercentage={p} />
      ))}
    </Row>
  )
}

function Star(props: { id: string; fillPercentage: number }) {
  const { id, fillPercentage } = props
  const clipId = `${id}-clip`
  const clipWidth = fillPercentage * 22
  return (
    <svg
      width="22"
      height="21"
      viewBox="0 0 22 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <defs>
        <clipPath id={clipId}>
          <rect x="0" y="0" width={clipWidth} height="21" />
        </clipPath>
      </defs>
      <path
        d="M9.8456 1.18286C10.1503 0.27295 11.4373 0.272949 11.7421 1.18286L13.7607 7.21022L20.1168 7.26745C21.0763 7.27609 21.474 8.5001 20.7028 9.0711L15.5942 12.8534L17.504 18.9162C17.7923 19.8314 16.7511 20.5879 15.9697 20.0309L10.7938 16.3412L5.61797 20.0309C4.83661 20.5879 3.79539 19.8314 4.08369 18.9162L5.99341 12.8534L0.884836 9.0711C0.113628 8.50011 0.511333 7.27609 1.47088 7.26745L7.82701 7.21022L9.8456 1.18286Z"
        fill="#D9D9D9"
      />
      <path
        d="M9.8456 1.18286C10.1503 0.27295 11.4373 0.272949 11.7421 1.18286L13.7607 7.21022L20.1168 7.26745C21.0763 7.27609 21.474 8.5001 20.7028 9.0711L15.5942 12.8534L17.504 18.9162C17.7923 19.8314 16.7511 20.5879 15.9697 20.0309L10.7938 16.3412L5.61797 20.0309C4.83661 20.5879 3.79539 19.8314 4.08369 18.9162L5.99341 12.8534L0.884836 9.0711C0.113628 8.50011 0.511333 7.27609 1.47088 7.26745L7.82701 7.21022L9.8456 1.18286Z"
        fill="#FA604A"
        clipPath={`url(#${clipId})`}
      />
    </svg>
  )
}
